import { forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';
import { selectDirection } from '../../../store/globalSlice';
import { isRtl } from '../../utils/utils';

const CustomScroll = forwardRef((props, ref) => {
  const direction = useSelector(selectDirection);
  const trackPosition = isRtl(direction) ? { left: '0' } : { right: '0' };

  const {
    autoHide,
    renderViewStyle = {},
    renderThumbVerticalStyle,
    renderThumbHorizontalStyle,
    renderTrackVerticalStyle,
    className,
    ...rest
  } = props;

  const renderView = ({ style, ...props }) => (
    <div {...props} style={{ ...style, ...renderViewStyle }} data-testid="scrollbar" className="scroll-bar" />
  );

  const renderTrackVertical = ({ style, ...props }) => (
    <div
      className="vertical-scrollbar"
      {...props}
      style={{
        ...style,
        width: '6px',
        height: '100%',
        top: 0,
        ...trackPosition,
        borderRadius: '5px',
        padding: '0px 0px 24px',
        ...renderTrackVerticalStyle,
      }}
      data-testid="vertical-scrollbar"
    />
  );

  const renderThumbVertical = ({ style, ...props }) => (
    <div
      {...props}
      style={{
        ...style,
        width: '4px',
        height: '150px',
        backgroundColor: 'rgba(48, 48, 48, 0.12)',
        borderRadius: '5px',
        marginInlineStart: '-4px',
        ...renderThumbVerticalStyle,
      }}
    />
  );

  const renderThumbHorizontal = ({ style, ...props }) => (
    <div
      {...props}
      className="horizontal-scrollbar"
      style={{
        ...style,
        width: '150px',
        height: '4px',
        backgroundColor: 'rgba(48, 48, 48, 0.12)',
        borderRadius: '5px',
        ...renderThumbHorizontalStyle,
      }}
      data-testid="horizontal-scrollbar"
    />
  );

  const renderTrackHorizontal = ({ style, ...props }) => (
    <div
      className="horizontal-scrollbar"
      {...props}
      style={{
        ...style,
        width: '100%',
        left: '0',
        bottom: '0',
        height: '10px !important',
      }}
      data-testid="horizontal-scrollbar"
    />
  );

  return (
    <Scrollbars
      {...rest}
      ref={ref}
      autoHide={autoHide}
      renderThumbVertical={renderThumbVertical}
      renderThumbHorizontal={renderThumbHorizontal}
      renderTrackVertical={renderTrackVertical}
      renderTrackHorizontal={renderTrackHorizontal}
      renderView={renderView}
      className={className}
    >
      {props.children}
    </Scrollbars>
  );
});

export default CustomScroll;
